export const tableColumn = [
	{
		label: '序号',
		width: '120px',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '员工状态',
		prop: 'probation',
		width: '120px',
		tableType: 'probationType',
		isCustomize: true,
	},
	{
		label: '通知内容',
		prop: 'content',
		isCustomize: true,
		tableType: 'dayNum',
	},
	{
		label: '通知规则',
		prop: 'day',
		width: '120px',
		isCustomize: true,
		tableType: 'dayFormat',
	}
]
