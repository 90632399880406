<template>
  <div class="xinsheng-warp system-warp">
    <TheadedTable
        :tableColumn="tableColumn"
        :tableData="tableData"
        @nameDrawer="handleNameDrawer"
        :page="pagination.page"
        :size="pagination.size"
        @changeInput="handleChange"
        @changeDayInput="changeDayInput"
    />
    <TheadedPagination
        :total="total"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
    />

    <CustomerDetail
        width="1200px"
        :isShowDialog="isShowDrawer"
        @close="handleDrawerClose"
        direction="rtl"
        :userId="drawerId"
    />
  </div>
</template>

<script>
import TheadedTable from "@/components/table";
import TheadedPagination from "@/components/pagination";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn } from "./index.js";
import { useStore } from "vuex";
import { reset } from "@/until";
import CustomerDetail from "@/components/drawer-form/index.vue";
import { putNoticeSettingContent } from "@/api/method/system";
import { ElMessage } from "element-plus";

export default {
  name: "Theaded",
  props: ["options", "departments"],
  components: {
    TheadedTable,
    TheadedPagination,
    CustomerDetail,
  },

  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      source: null,
    });

    onMounted(() => {
      getList();
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(() => state.noticeSettingList && state.noticeSettingList.records || []);
    const total = computed(() => state.noticeSettingList && state.noticeSettingList.total);
    const getList = () => {
      dispatch("noticeSettingList", {
        ...filterData,
        ...pagination,
      });
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const isShowDrawer = ref(false);
    const handleDrawerClose = () => (isShowDrawer.value = false);
    const drawerId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDrawer.value = true;
      drawerId.value = row.costumerId;
    };

    const handleChange = async (row) => {
      const id = row.id;
      const content = row.content;

      const res = await putNoticeSettingContent({ id, content });
      if (res.code == 200) {
        ElMessage.success("修改成功！");
        getList();
      } else {
        ElMessage.error(res.msg);
      }
    };

    const changeDayInput = async (row) => {
      const id = row.id;
      const day = row.day;

      const res = await putNoticeSettingContent({ id, day });
      if (res.code == 200) {
        ElMessage.success("修改成功！");
        getList();
      } else {
        ElMessage.error(res.msg);
      }
    };

    return {
      filterData,
      handleSubmit,
      handleReset,
      tableData,
      tableColumn,
      total,
      isShowDrawer,
      handleDrawerClose,
      handleNameDrawer,
      drawerId,
      currentChange,
      sizeChange,
      pagination,
      handleChange,
      changeDayInput,
    };
  },
};
</script>

<style>
</style>